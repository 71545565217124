import React, { useEffect } from 'react'
import { GetServerSideProps, Redirect } from 'next'
import Head from 'next/head'
import { Product } from '@Types/product/Product'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import GASnippet from 'components/GASnippet'
import { useFormat } from 'helpers/hooks/useFormat'
import { createClient, ResponseError, LocaleStorage, useDarkMode } from 'frontastic'
import { FrontasticRenderer } from 'frontastic/lib/renderer'
import { tastics } from 'frontastic/tastics'
import { FRONTASTIC_HOST } from 'helpers/constants/envVariables'
import { GA_TRACKING_ID } from 'helpers/constants/googleAnalytics'
import { Log } from 'helpers/errorLogger'
import styles from './slug.module.css'
import { mapLanguage } from 'project.config'

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string
}

export default function Slug({ data, locale }: SlugProps) {
  LocaleStorage.locale = locale

  const { applyTheme } = useDarkMode()

  useEffect(() => {
    applyTheme(data?.pageFolder?.configuration?.theme)
  }, [data?.pageFolder?.configuration])

  const { formatMessage } = useFormat({ name: 'common' })

  // SEO for PDP
  let product: Product | null = null

  if (data?.data?.dataSources) {
    product =
      '__master' in data?.data?.dataSources
        ? data?.data?.dataSources?.__master?.product
        : data?.data?.dataSouces?.product
  }

  const mappedLocale = mapLanguage(locale)
  const seoMeta = data?.pageFolder?.configuration
  const seoTitle = product?.metaTitle || seoMeta?.seoTitle?.[mappedLocale]
  const seoDescription = product?.metaDescription || seoMeta?.seoDescription?.[mappedLocale]
  const seoKeywords = seoMeta?.seoKeywords?.[mappedLocale]

  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    )
  }

  if (!data!.ok && data!.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data!.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    )
  }

  return (
    <>
      <Head>
        <link rel="dns-prefetch preconnect" href="https://consentcdn.cookiebot.com" />
        <link rel="dns-prefetch preconnect" href="https://www.googletagmanager.com" />
        <link rel="dns-prefetch preconnect" href="https://www.google-analytics.com" />
        <link rel="dns-prefetch preconnect" href={FRONTASTIC_HOST} />
        {product && (
          // <link rel="canonical" href={productHelper.generateUrlFor(product, locale)} />
          // Use canonical to homepage for now
          <link rel="canonical" href="/" />
        )}
        {GA_TRACKING_ID && (
          <link rel="preload" as="script" href={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
        )}

        {seoTitle && <title>{seoTitle}</title>}
        {seoDescription && <meta name="description" content={seoDescription} />}
        {seoKeywords && <meta name="keywords" content={seoKeywords} />}
      </Head>
      <GASnippet />
      <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />
    </>
  )
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({ params, locale, query, req, res }) => {
  LocaleStorage.locale = locale

  const frontastic = createClient()
  const data = await frontastic.getRouteData(params, locale, query, req, res)

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      return {
        notFound: true,
      }
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      }
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data)
    return {
      notFound: true,
    }
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    }
  }

  if ((data as any)!.message === 'Could not resolve page from path') {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      data: data || null,
      locale,
      ...(await serverSideTranslations(locale, [
        'common',
        'cart',
        'product',
        'checkout',
        'account',
        'error',
        'success',
        'wishlist',
        'newsletter',
      ])),
    },
  }
}
